/*------------------------------------------------
// File Name:PDM_MA_SchdTime.js
// File Description:PDM_MA_SchdTime API方法js接口
// Author:zxl
// Create Time:2023/06/06 15:36:13
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maSchdtime: "/PDM_MA_SchdTime"
}

export default api


//-------------------创建体检预约排班表-时间对象-------------------
export function CreatePDM_MA_SchdTimeEntity(parameter) {
    return axios({
        url: api._maSchdtime + "/CreatePDM_MA_SchdTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取体检预约排班表-时间列表-------------------
export function GetPDM_MA_SchdTimeList(parameter) {
    return axios({
        url: api._maSchdtime + "/GetPDM_MA_SchdTimeList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取体检预约排班表-时间列表---------------
export function GetPDM_MA_SchdTimeListByPage(parameter) {
    return axios({
        url: api._maSchdtime + "/GetPDM_MA_SchdTimeListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取体检预约排班表-时间对象---------------
export function GetPDM_MA_SchdTimeEntity(parameter) {
    return axios({
        url: api._maSchdtime + "/GetPDM_MA_SchdTimeEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存体检预约排班表-时间对象---------------
export function UpdatePDM_MA_SchdTimeEntity(parameter) {
    return axios({
        url: api._maSchdtime+ "/UpdatePDM_MA_SchdTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉体检预约排班表-时间对象---------------
export function DeletePDM_MA_SchdTimeEntity(parameter) {
    return axios({
        url: api._maSchdtime + "/DeletePDM_MA_SchdTimeEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID修改排班时间---------------
export function UpdateSchdTimeData(parameter) {
    return axios({
        url: api._maSchdtime + "/UpdateSchdTimeData",
        method: 'post',
        data: parameter
    })
}

//-------------------根据排班ID获取排班时间---------------
export function GetSchdTimeData(parameter) {
    return axios({
        url: api._maSchdtime + "/GetSchdTimeData",
        method: 'post',
        data: parameter
    })
}


